import request from '@/utils/request'

// 查询所有项目部
export function projectDepartment (parameter) {
  return request({
    url: '/api/back/employmentEmploy/SelectController/queryAllProjectDepartmentByEnterpriseUserId',
    method: 'get',
    params: parameter
  })
}

// 根据项目部id分页查询项目
export function employmentEmployProject (parameter) {
  return request({
    url: '/api/back/employmentEmploy/SelectController/queryAllProjectFromProjectDepartment',
    method: 'get',
    params: parameter
  })
}

// 分页查询人员类别
export function employmentEmploypersonnelType (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterprisePublishDemandController/page',
    method: 'get',
    params: parameter
  })
}

// 根据人员类别分页查询技术/专业人员职位
export function employmentEmployDictPosition (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterprisePublishDemandController/queryAllPositionByType',
    method: 'get',
    params: parameter
  })
}

// 根据人员类别和职位id查询基本/专业技能
export function employmentEmploybaseSkill (parameter) {
  return request({
    url: '/api/back/employmentEmploy/baseSkill',
    method: 'get',
    params: parameter
  })
}

// 查询从业资格
export function qualificationCertificatePage (parameter) {
  return request({
    url: '/api/back/employmentEmploy/dict/qualificationCertificate/page',
    method: 'get',
    params: parameter
  })
}

// 查询所有省
export function districtQueryAllProvince (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterprisePublishDemandController/queryAllProvince',
    method: 'get',
    params: parameter
  })
}

// 查询所有省市区
export function getAreaTree (parameter) {
  return request({
    url: '/api/back/employmentEmploy/district/getTree',
    method: 'get'
  })
}

// 根据pid查询地理位置
export function employmentEmployDistrict (pid) {
  return request({
    url: `/api/back/employmentEmploy/district/${pid}`,
    method: 'get'
  })
}
// 发布
export function employmentEmploypublish (parameter) {
  return request({
    url: `/api/enterprise/employmentEmploy/enterprisePublishDemandController/publishDemand`,
    method: 'post',
    data: parameter
  })
}
// 保存为我的模板
export function employmentEmploytemplateDemand (parameter) {
  return request({
    url: `/api/back/employmentEmploy/template_demand`,
    method: 'post',
    data: parameter
  })
}

// 编辑我的模板
export function updateTemplateDemand (parameter) {
  return request({
    url: `/api/enterprise/employmentEmploy/TemplateController/saveOrUpdate`,
    method: 'post',
    data: parameter
  })
}

// 查询招标信息详情
export function employmentEmploytemplateDemandById (params) {
  return request({
    url: `/api/back/tender/ddyptController/getInfo`,
    method: 'get',
    params: {
      ...params
    }
  })
}

// 我的模板
export function enterprisePublishDemandController (parameter) {
  return request({
    url: `/api/enterprise/employmentEmploy/TemplateController/getMy`,
    method: 'get',
    params: parameter
  })
}

// 根据用工需求模板ID查询模板详情
export function enterprisePublishDemand (parameter) {
  return request({
    url: `/api/enterprise/employmentEmploy/TemplateController/getMyTemplate`,
    method: 'get',
    params: parameter
  })
}

// 企业端-发布用工需求-系统模板详情回显
export function enterprisePublishDemandBySystem (parameter) {
  return request({
    url: `/api/enterprise/employmentEmploy/TemplateController/getSysTemplate`,
    method: 'get',
    params: parameter
  })
}

// 根据用工需求模板ID查询模板详情(工作台-用工模板详情专用)
export function enterprisePublishDemand1 (parameter) {
  return request({
    url: `/api/enterprise/employmentEmploy/TemplateController/getTemplateDemandInfo`,
    method: 'get',
    params: parameter
  })
}
// 查询系统模板
export function enterpriseSystemPublishDemandController (params) {
  return request({
    url: `/api/enterprise/employmentEmploy/TemplateController/getSys`,
    method: 'get',
    params: {
      ...params
    }
  })
}
