<template>
  <div class="workDemand__wrapper">
    <div class="body_content">
      <div id="common_box">
        <!-- <a-breadcrumb class="breadcrumb">
          <a-breadcrumb-item>首页</a-breadcrumb-item>
          <a-breadcrumb-item>发布用工需求</a-breadcrumb-item>
        </a-breadcrumb>-->
        <div class="commom_title">
          <a-row type="flex">
            <a-col :span="16">
              <a-row>
                <a-col :span="1">
                  <div class="commom_line"></div>
                </a-col>
                <a-col :span="10">发布岗位需求</a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
        <div class="content">
          <div class="left_content">
            <div class="left_content_header">
              <div
                @click="handleClickTemplate('system')"
                :class="{
                  template_btn: true,
                  active: templateType === 'system',
                }"
              >
                系统模版
              </div>
              <div
                :class="{
                  template_btn: true,
                  active: templateType === 'my',
                }"
                @click="handleClickTemplate('my')"
              >
                我的模版
              </div>
            </div>
            <div class="left_content_search">
              <a-input-search
                enter-button="搜索"
                @search="handleGetTempalte"
                v-model="templateName"
                placeholder="请输入模板名称"
                allowClear
                class="search_inp"
              />
            </div>
            <div class="left_content_body">
              <a-spin :spinning="leftLoading">
                <a-list :data-source="templateData">
                  <a-list-item
                    slot="renderItem"
                    slot-scope="item, index"
                    @click="handleClickItem(item)"
                    :class="{ active: item.templateId === temId }"
                  >
                    <span>{{ item.templateName }}</span>
                  </a-list-item>
                </a-list>
              </a-spin>
            </div>
          </div>
          <div class="right_content">
            <a-spin :spinning="rightLoading">
              <a-form-model
                ref="form"
                :model="form"
                :rules="rules"
                :label-col="{ span: 6 }"
                :wrapper-col="{ span: 17 }"
              >
                <a-row class="form__title">
                  <a-col :span="12">
                    <a-form-model-item label="项目部" prop>
                      <a-select
                        :options="options"
                        allowClear
                        @change="handleGetProjectList"
                        v-model="form.projectDepartmentId"
                        placeholder="请选择项目部"
                      ></a-select>
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-model-item label="项目名称" prop>
                      <a-select
                        :options="employmentEmploylist"
                        v-model="form.projectId"
                        allowClear
                        placeholder="请选择项目名称"
                      ></a-select>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <div class="content_item" v-for="(item, index) in form.demandInfoList" :key="index">
                  <a-row type="flex" class="item_title">
                    <a-icon
                      type="minus-circle"
                      :class="{
                        icon: true,
                        disabled: form.demandInfoList.length === 1,
                      }"
                      @click="handleDeleteForm(index)"
                    />
                    <a-col :span="9">
                      <a-form-model-item
                        label="人员类别"
                        :prop="'demandInfoList.' + index + '.type'"
                        :rules="rules.type"
                      >
                        <a-select
                          :options="personnelType"
                          placeholder="请选择人员类别"
                          allowClear
                          @change="handleGetPositionData($event, index)"
                          v-model="item.type"
                        ></a-select>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                      <a-form-model-item
                        :label="getLabel(index)"
                        :prop="'demandInfoList.' + index + '.positionId'"
                        :rules="rules.positionId"
                      >
                        <a-select
                          :options="dictPosition[index]"
                          :placeholder="`请选择${getLabel(index)}`"
                          allowClear
                          @change="handleGetSkill(item.type, $event, index)"
                          v-model="item.positionId"
                        ></a-select>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <div class="item__content">
                    <a-row>
                      <a-col :span="12">
                        <a-form-model-item
                          label="基本技能"
                          :prop="'demandInfoList.' + index + '.baseSkillIds'"
                          :rules="rules.baseSkillIds"
                        >
                          <a-checkbox-group v-model="item.baseSkillIds">
                            <a-checkbox v-for="items in baseSkillDtoList[index]" :key="items.name" :value="items.id">{{
                              items.name
                            }}</a-checkbox>
                          </a-checkbox-group>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="24" v-if="item.type == 1">
                        <a-form-model-item
                          :label-col="{ span: 3 }"
                          :wrapper-col="{ span: 21 }"
                          label="专业技能"
                          :prop="'demandInfoList.' + index + '.professionalSkillIds'"
                          :rules="
                            professionalSkillDtoList[index].length ? { validator: validProfessionalSkillIds } : null
                          "
                          :class="professionalSkillDtoList[index].length ? 'required' : ''"
                        >
                          <a-checkbox-group v-model="item.professionalSkillIds">
                            <a-checkbox
                              v-for="items in professionalSkillDtoList[index]"
                              :key="items.name"
                              :value="items.id"
                            >{{ items.name }}</a-checkbox
                            >
                          </a-checkbox-group>
                        </a-form-model-item>
                      </a-col>
                      <a-col :span="24" v-if="item.type == 2">
                        <a-form-model-item
                          :label-col="{ span: 3 }"
                          :wrapper-col="{ span: 21 }"
                          label="从业资格证"
                          :prop="'demandInfoList.' + index + '.qualificationCertificateIds'"
                          :rules="{ validator: validQualificationCertificateIds }"
                          class="required"
                        >
                          <a-checkbox-group v-model="item.qualificationCertificateIds">
                            <a-checkbox v-for="items in requirements" :key="items.name" :value="items.id">{{
                              items.name
                            }}</a-checkbox>
                          </a-checkbox-group>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="24">
                        <a-form-model-item
                          label="项目经历"
                          :label-col="{ span: 3 }"
                          :wrapper-col="{ span: 21 }"
                          :prop="'demandInfoList.' + index + '.experience'"
                          :rules="rules.experience"
                        >
                          <a-radio-group name="radioGroup" v-model="item.experience">
                            <a-radio :value="1">30万机组以下</a-radio>
                            <a-radio :value="2">30-60万机组</a-radio>
                            <a-radio :value="3">100万及以上机组</a-radio>
                            <a-radio :value="0">不限</a-radio>
                          </a-radio-group>
                        </a-form-model-item>
                      </a-col>
                      <a-col :span="24">
                        <a-form-model-item
                          label="技能等级证"
                          :label-col="{ span: 3 }"
                          :wrapper-col="{ span: 21 }"
                          :prop="'demandInfoList.' + index + '.skillLevelCertificate'"
                          :rules="rules.skillLevelCertificate"
                        >
                          <a-radio-group name="radioGroup" v-model="item.skillLevelCertificate">
                            <a-radio :value="1">初级</a-radio>
                            <a-radio :value="2">中级</a-radio>
                            <a-radio :value="3">高级</a-radio>
                            <a-radio :value="-1">特殊工种证书</a-radio>
                            <a-radio :value="0">不限</a-radio>
                          </a-radio-group>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="12">
                        <a-form-model-item
                          label="招聘人数"
                          :prop="'demandInfoList.' + index + '.number'"
                          :rules="rules.number"
                        >
                          <a-input-number
                            style="width: 120px"
                            :max="99999999"
                            :min="1"
                            v-model="item.number"
                            :formatter="(value) => `${value}`"
                          />
                          人
                        </a-form-model-item>
                      </a-col>
                      <a-col :span="12">
                        <a-form-model-item
                          label="薪资标准"
                          :prop="'demandInfoList.' + index + '.salaryType'"
                          :autoLink="false"
                          ref="salary"
                          required
                          :rules="rules.salaryType"
                        >
                          <a-input-number
                            style="width: 100px"
                            :max="9999999999"
                            :min="1"
                            v-model="item.startSalary"
                            @blur="
                              () => {
                                $refs.salary[0].onFieldBlur()
                              }
                            "
                            @change="
                              () => {
                                $refs.salary[0].onFieldChange()
                              }
                            "
                            :formatter="(value) => `${value}`"
                          />
                          -
                          <a-input-number
                            style="width: 100px"
                            :max="9999999999"
                            :min="1"
                            v-model="item.endSalary"
                            @blur="
                              () => {
                                $refs.salary[0].onFieldBlur()
                              }
                            "
                            @change="
                              () => {
                                $refs.salary[0].onFieldChange()
                              }
                            "
                            :formatter="(value) => `${value}`"
                          />
                          <a-select
                            default-value="元/天"
                            style="width: 100px; margin-left: 10px"
                            v-model="item.salaryType"
                          >
                            <a-select-option value="元/天"> 元/天 </a-select-option>
                            <a-select-option value="元/小时"> 元/小时 </a-select-option>
                            <a-select-option value="元/月"> 元/月 </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="12">
                        <a-form-model-item
                          label="食宿"
                          :prop="'demandInfoList.' + index + '.boardLodging'"
                          :rules="rules.boardLodging"
                        >
                          <a-radio-group name="radioGroup" v-model="item.boardLodging">
                            <a-radio :value="0">包吃</a-radio>
                            <a-radio :value="1">包住</a-radio>
                            <a-radio :value="2">包吃住</a-radio>
                            <a-radio :value="3">不提供</a-radio>
                          </a-radio-group>
                        </a-form-model-item>
                      </a-col>
                      <a-col :span="12">
                        <a-form-model-item label="路费" :prop="'demandInfoList.' + index + '.toll'" :rules="rules.toll">
                          <a-radio-group name="radioGroup" v-model="item.toll">
                            <a-radio :value="0">单程</a-radio>
                            <a-radio :value="1">双程</a-radio>
                            <a-radio :value="2">不提供</a-radio>
                            <a-radio :value="3">来程算工资</a-radio>
                          </a-radio-group>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="12">
                        <a-form-model-item
                          label="工作地点"
                          :prop="'demandInfoList.' + index + '.place'"
                          :rules="rules.place"
                        >
                          <a-cascader
                            :options="areaOption"
                            v-model="item.place"
                            :fieldNames="{
                              label: 'district',
                              value: 'district',
                              children: 'children',
                            }"
                            placeholder="请选择工作地点"
                          />
                        </a-form-model-item>
                      </a-col>
                      <a-col :span="12">
                        <a-form-model-item
                          label="工作时间"
                          :prop="'demandInfoList.' + index + '.workTime'"
                          :rules="rules.workTime"
                        >
                          <a-range-picker
                            value-format="YYYY-MM-DD"
                            v-model="item.workTime"
                            :disabledDate="disabledDate"
                          />
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="12">
                        <a-form-model-item
                          label="岗位职责"
                          :prop="'demandInfoList.' + index + '.duty'"
                          :rules="rules.duty"
                        >
                          <a-textarea
                            :maxLength="666"
                            v-model="item.duty"
                            placeholder="例：
1，负责***产品工艺系统流程设计；
2，负责***产品设计过程中系统设备选型；
3，负责***产品工程应用中的施工图设计"
                            :rows="4"
                          />
                        </a-form-model-item>
                      </a-col>
                      <a-col :span="12">
                        <a-form-model-item
                          label="职位描述"
                          :prop="'demandInfoList.' + index + '.jobDescription'"
                          :rules="rules.jobDescription"
                        >
                          <a-textarea
                            :maxLength="666"
                            v-model="item.jobDescription"
                            placeholder="例：
1，负责***产品工艺系统流程设计；
2，负责***产品设计过程中系统设备选型；
3，负责***产品工程应用中的施工图设计"
                            :rows="4"
                          />
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                  </div>
                </div>
              </a-form-model>
            </a-spin>

            <div class="form_add" @click="handleAddForm">
              <a-icon type="plus-circle" style="margin-right: 10px" />继续添加岗位
            </div>
            <div class="btn_box">
              <a-button class="btn__button" @click="handleOpenModal">保存为我的模版</a-button>
              <a-button class="btn__button" type="primary" :loading="pageLoading" @click="handleSubmit">发布</a-button>
            </div>
          </div>
        </div>
      </div>
      <a-modal
        centered
        v-model="isShow"
        :title="title"
        @ok="handleOk"
        okText="确认"
        cancelText="取消">
        <p class="modal_text" v-if="title == '发布成功'">
          您的物资需求已成功发布，可在“控制台-物资订单”选择合适的供应商进行确定合作意向。
        </p>
        <p class="modal_text" v-if="title == '保存成功'">
          您的用工需求模版已成功保存，可在“控制台-模版管理”进行编辑与查看。
        </p>
      </a-modal>
      <a-modal
        centered
        :visible="visible"
        title="保存模板"
        @ok="handleSave"
        @cancel="handleCancel"
        okText="确认"
        cancelText="取消"
        :confirmLoading="loading"
      >
        <p class="modal_inp">
          <a-col :span="6">
            <div class="modal_name">模版名称</div>
          </a-col>
          <a-col :span="12">
            <a-input v-model="name" placeholder="请输入模板名称" />
          </a-col>
        </p>
      </a-modal>
    </div>
  </div>
</template>

<script>
import {
  employmentEmploybaseSkill,
  employmentEmployDictPosition,
  employmentEmploypersonnelType,
  employmentEmployProject,
  employmentEmploypublish,
  employmentEmploytemplateDemand,
  enterprisePublishDemand,
  enterprisePublishDemandController,
  projectDepartment,
  updateTemplateDemand,
  getAreaTree,
  enterpriseSystemPublishDemandController
} from '@/api/workDemand'
import { Cascader } from 'ant-design-vue'
import moment from 'moment'

const templateParams = {
  pageIndex: 1,
  pageSize: 1000
}

export default {
  name: 'Index',
  components: {
    'a-cascader': Cascader
  },
  data () {
    const validSalary = (rule, value, callback) => {
      const index = rule.field.split('.')[1]
      const { startSalary, endSalary } = this.form.demandInfoList[index]
      if (!startSalary || !endSalary) {
        callback(new Error('请填写完整薪资标准'))
      } else {
        callback()
      }
    }
    const validTime = (rule, value, callback) => {
      const index = rule.field.split('.')[1]
      const { workTime = [] } = this.form.demandInfoList[index]
      const [start, end] = workTime
      // console.log(start, end, )
      if (start && end && moment(start).add(1, 'year').format('YYYY-MM-DD') <= end) {
        callback(new Error('工作时间跨度不得超过一年'))
      } else {
        callback()
      }
    }
    return {
      form: {
        demandInfoList: [
          {
            salaryType: '元/天'
          }
        ]
      },
      rules: {
        type: { required: true, message: '请选择人员类别', trigger: 'change' },
        positionId: { required: true, message: '请选择职位', trigger: 'change' },
        baseSkillIds: { required: true, message: '请选择基本技能', trigger: 'change' },
        experience: { required: true, message: '请选择项目经历', trigger: 'change' },
        skillLevelCertificate: { required: true, message: '请选择技能等级证', trigger: 'change' },
        number: { required: true, message: '请输入招聘人数', trigger: 'blur' },
        salaryType: [{ validator: validSalary }],
        boardLodging: { required: true, message: '请选择食宿', trigger: 'change' },
        toll: { required: true, message: '请选择路费', trigger: 'change' },
        place: { required: true, message: '请选择工作地点', trigger: 'change' },
        workTime: [{ required: true, message: '请选择工作时间', trigger: 'change' }, { validator: validTime }],
        duty: { required: true, message: '请输入岗位职责', trigger: 'blur' },
        jobDescription: { required: true, message: '请输入职位描述', trigger: 'blur' }
      },
      requirements: [
        { name: '安全员', id: '1' },
        { name: '建造师', id: '2' },
        { name: '不限', id: '0' }
      ],
      areaOption: [],
      isSave: false,
      title: '',
      isShow: false,
      visible: false,
      options: [],
      employmentEmploylist: [],
      personnelType: [],
      dictPosition: [],
      baseSkillDtoList: [],
      professionalSkillDtoList: [],
      name: '',
      projectId: '',
      projectDepartmentId: '',
      id: '',
      templateType: 'system',
      templateName: '',
      templateData: [],
      leftLoading: false,
      rightLoading: false,
      temId: '',
      loading: false,
      pageLoading: false,
      salaryTypeList: ['元/天', '元/小时', '元/月']
    }
  },
  mounted () {
    this.$init()
  },
  methods: {
    $init () {
      this.handleGetTempalte()
      this.handleGetBasicData()
    },
    disabledDate (current) {
      if (current.format('YYYY-MM-DD') <= moment(moment().subtract(1, 'month')).format('YYYY-MM-DD')) {
        return true
      }
      return false
    },
    handleGetBasicData () {
      projectDepartment() // 项目部
        .then((res) => {
          this.options = res.data.map((item) => ({
            ...item,
            label: item.projectDepartment,
            value: item.projectDepartmentId
          }))
        })
      getAreaTree() // 城市
        .then((res) => {
          this.areaOption = res
        })
      employmentEmploypersonnelType() // 人员类别
        .then((res) => {
          this.personnelType = res.data.map((item) => ({
            ...item,
            label: item.name,
            value: item.id.toString()
          }))
        })
    },
    handleGetTempalte () {
      this.leftLoading = true
      const { templateName, templateType } = this
      const fun = templateType === 'my' ? enterprisePublishDemandController : enterpriseSystemPublishDemandController
      fun({
        ...templateParams,
        templateName
      })
        .then((res) => {
          this.templateData = res.data
          // if (!this.templateData.some((item) => item.templateId === this.temId)) {
          //   this.temId && this.$refs.form.clearValidate()
          //   this.temId && this.$set(this, 'form', { demandInfoList: [{}] })
          //   this.temId = ''
          // }
        })
        .finally(() => {
          this.leftLoading = false
        })
    },
    handleClickTemplate (type) {
      if (this.templateType !== type) {
        this.templateType = type
        this.handleGetTempalte()
      }
    },
    handleClickItem (leftRow) {
      if (this.temId !== leftRow.templateId) {
        this.temId = leftRow.templateId
        this.rightLoading = true
        enterprisePublishDemand({ templateName: leftRow.templateName })
          .then((res) => {
            const data = res.data
            this.$set(this, 'form', {
              ...this.form,
              demandInfoList: data.map((model) => {
                const salaryType = this.salaryTypeList.find((item) => model.salary.includes(item))
                const [startSalary, endSalary] = model.salary.split(salaryType)[0].split('-')
                return {
                  ...model,
                  workTime: [
                    moment(model.workStartTime || model.projectStartTime).format('YYYY-MM-DD'),
                    moment(model.workEndTime || model.projectEndTime).format('YYYY-MM-DD')
                  ],
                  salaryType,
                  startSalary,
                  endSalary,
                  baseSkillIds: model.baseSkillIds.map(item => Number(item)),
                  professionalSkillIds: model.baseSkillIds.map(item => Number(item)),
                  qualificationCertificateIds: model.baseSkillIds.map(item => Number(item)),
                  place: model.place.split('-')
                }
              })
            })
            data.forEach((item, index) => {
              this.handleGetPositionData(item.type, index, item.positionId)
              this.handleGetSkill(item.type, item.positionId, index)
              this.$set(this.form.demandInfoList[index], 'professionalSkillIds', item.professionalSkillIds.map(item => Number(item)))
            })
          })
          .finally(() => {
            this.rightLoading = false
          })
      } else {
        this.temId = ''
      }
    },
    handleGetProjectList (projectDepartmentId) {
      // 项目
      this.$set(this.form, 'projectId', undefined)
      this.employmentEmploylist = []
      if (projectDepartmentId) {
        employmentEmployProject({ projectDepartmentId }).then((res) => {
          this.employmentEmploylist = res.data.map((item) => ({
            ...item,
            label: item.projectName,
            value: item.projectId
          }))
        })
      }
    },
    handleGetPositionData (type, index, positionId = undefined) {
      this.$set(this.dictPosition, index, [])
      this.$set(this.form.demandInfoList[index], 'positionId', positionId)
      if (!positionId) {
        this.$set(this.form.demandInfoList[index], 'baseSkillIds', [])
        this.$set(this.form.demandInfoList[index], 'qualificationCertificateIds', [])
      }
      this.$set(this.baseSkillDtoList, index, [])
      this.$nextTick(() => {
        this.$refs.form.clearValidate([
          `demandInfoList.${index}.professionalSkillIds`,
          `demandInfoList.${index}.qualificationCertificateIds`
        ])
      })
      // 职位
      if (type) {
        employmentEmployDictPosition({ type }).then((res) => {
          const data = res.data.map((item) => ({
            ...item,
            label: item.majorName,
            value: item.id.toString()
          }))
          this.$set(this.dictPosition, index, data)
        })
        this.handleGetSkill(type, null, index)
      }
    },
    handleGetSkill (type, positionId, index) {
      this.$set(this.professionalSkillDtoList, index, [])
      this.$set(this.form.demandInfoList[index], 'professionalSkillIds', [])
      // 技能
      employmentEmploybaseSkill({
        type,
        positionId
      }).then((res) => {
        if (res.data) {
          !positionId && this.$set(this.baseSkillDtoList, index, res.data.baseSkillDtoList || [])
          positionId && this.$set(this.professionalSkillDtoList, index, res.data.professionalSkillDtoList || [])
          if (!res.data.professionalSkillDtoList.length) {
            this.$refs.form.clearValidate(`demandInfoList.${index}.professionalSkillIds`)
          }
        }
      })
    },
    handleAddForm () {
      const length = this.form.demandInfoList.length
      this.$set(this.form.demandInfoList, length, {
        salaryType: '元/天'
      })
    },
    handleDeleteForm (index) {
      if (this.form.demandInfoList.length === 1) {
        return
      }
      this.form.demandInfoList.splice(index, 1)
      this.form.dictPosition.splice(index, 1)
      this.form.baseSkillDtoList.splice(index, 1)
      this.form.professionalSkillDtoList.splice(index, 1)
    },
    handleOpenModal () {
      // const validArr = this.form.demandInfoList.reduce((arr, item, index) => {
      //   return [...arr, `demandInfoList.${index}.type`, `demandInfoList.${index}.positionId`]
      // }, [])
      // let result = true
      // this.$refs.form.validateField(validArr, (err) => {
      //   if (!result) {
      //     result = false
      //   } else {
      //     result = !err
      //   }
      // })
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.temId && this.templateType !== 'system') {
            this.name = this.templateData.find((item) => item.templateId === this.temId).templateName
          }
          this.visible = true
        }
      })
    },
    handleSave () {
      if (!this.name) {
        this.$message.error('请输入模板名称')
      } else {
        // if (this.templateType === 'my' && this.temId) {

        // } else {
        //   fun = employmentEmploytemplateDemand
        //   modelList = this.form.demandInfoList.map((item) => ({
        //     ...item,
        //     templateName: this.name,
        //     positionId: item.positionId,
        //     type: item.type
        //   }))
        // }
        const modelList = this.form.demandInfoList.map((item) => {
          // eslint-disable-next-line prefer-const
          let { startSalary, endSalary, salaryType } = item
          const [workStartTime, workEndTime] = item.workTime
          if (endSalary < startSalary) {
            ;[startSalary, endSalary] = [endSalary, startSalary]
          }
          return {
            ...item,
            place: item.place.join('-'),
            salary: `${startSalary}-${endSalary}${salaryType}`,
            workStartTime,
            workEndTime,
            templateName: this.name,
            templateId: this.temId ? item.templateId : null
          }
        })
        this.loading = true
        updateTemplateDemand(modelList)
          .then(() => {
            this.$message.success('模板保存成功')
            this.handleGetTempalte()
            this.handleCancel()
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleCancel () {
      this.visible = false
      this.name = ''
    },
    handleFormatModel () {
      return {
        ...this.form,
        demandInfoList: this.form.demandInfoList.map((item) => {
          // eslint-disable-next-line prefer-const
          let { startSalary, endSalary, salaryType } = item
          const [workStartTime, workEndTime] = item.workTime
          if (endSalary < startSalary) {
            ;[startSalary, endSalary] = [endSalary, startSalary]
          }
          return {
            ...item,
            place: item.place.join('-'),
            salary: `${startSalary}-${endSalary}${salaryType}`,
            workStartTime,
            workEndTime
          }
        })
      }
    },
    handleSubmit () {
      if (!this.form.projectId) {
        this.$message.error('请选择项目名称')
      } else {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.rightLoading = true
            this.pageLoading = true
            const model = this.handleFormatModel()
            employmentEmploypublish(model)
              .then(() => {
                this.$message.success('职位发布成功')
                this.$set(this, 'form', { demandInfoList: [{ salaryType: '元/天', workTime: [] }] })
                this.temId && this.handleClickItem({ templateId: this.temId })
              })
              .finally(() => {
                this.rightLoading = false
                this.pageLoading = false
              })
          }
        })
      }
    },
    handleOk () {
      this.isSave = false
      this.isShow = false
    },
    getLabel (index) {
      const data = this.form.demandInfoList[index].type
      return data === '2' ? '专业' : '工种'
    },
    validProfessionalSkillIds (rule, value, callback) {
      const index = Number(rule.field.substr(15, 1))
      console.log(index)
      if (this.form.demandInfoList[index].type === '2') {
        callback()
      } else {
        if (!value || !value.length) {
          callback(new Error('请选择专业技能'))
        }
        callback()
      }
    },
    validQualificationCertificateIds (rule, value, callback) {
      const index = Number(rule.field.substr(15, 1))
      if (this.form.demandInfoList[index].type === '1') {
        callback()
      } else {
        if (!value || !value.length) {
          callback(new Error('请选择从业资格证'))
        }
        callback()
      }
    }
  }
}
</script>

<style lang="less" scoped>
  ::v-deep .ant-checkbox-group{
    width: 500px;
  }
</style>
<style lang="less">
@import url('../../common/style');
.workDemand__wrapper {
  .required {
    .ant-form-item-label {
      label {
        position: relative;
        &::before {
          display: inline-block;
          margin-right: 4px;
          color: #f5222d;
          font-size: 14px;
          font-family: SimSun, sans-serif;
          line-height: 1;
          content: '*';
        }
      }
    }
  }
  .content {
    display: flex;
    .left_content {
      width: 260px;
      margin-right: 30px;
      .left_content_header {
        display: flex;
        justify-content: space-between;
      }
      .left_content_body {
        margin-top: 10px;
        height: calc(100% - 150px);
        overflow: auto;
        .ant-list-item {
          background: #ffffff;
          border: none;
          padding-left: 26px;
          cursor: pointer;
          &:hover {
            background: #f0f0f0;
            color: #1677ff;
          }
          &.active {
            background: #f0f0f0;
            color: #1677ff;
          }
        }
      }
    }
    .right_content {
      flex: 1;
      .content_item {
        margin-top: 24px;
      }
      .form__title {
        padding: 24px 0 0 0;
        background: #f5f5f5;
        opacity: 1;
        margin-bottom: 24px;
      }
      .item_title {
        margin-bottom: 0px;
        .ant-form-item-label {
          label {
            font-size: 18px;
            font-weight: 500;
            color: #000000;
          }
        }
      }
      .icon {
        font-size: 18px;
        margin-top: 10px;
        margin-right: 10px;
        &.disabled {
          cursor: not-allowed;
        }
      }
    }
  }
  .template_btn {
    width: 110px;
    height: 36px;
    background: #dedede;
    margin: 20px 0;
    cursor: pointer;
    text-align: center;
    line-height: 36px;
    color: #fff;
    &.active {
      background: #1677ff;
    }
  }
  .item__content {
    border: 1px dashed #707070;
  }
  .form_add {
    width: 150px;
    margin: 20px auto;
    color: #1677ff;
    font-size: 18px;
    cursor: pointer;
  }
  .btn_box {
    display: flex;
    justify-content: flex-end;
    .btn__button {
      width: 150px;
      text-align: center;
      margin-left: 10px;
    }
  }
}
</style>
